import { EXPENSES } from "constants/serviceNames";
import { EXPENSE_CLAIMS } from "constants/taskNames";

const expenseClaimsMapper = {
    mapAPIExpenseClaimsToClaims(expenseClaimsList) {
        if (expenseClaimsList.count && expenseClaimsList.data) {
            return {
                count: expenseClaimsList.count,
                data: expenseClaimsList.data.map((expenseClaims) => ({
                    id: expenseClaims.id,
                    displayState: expenseClaims.claim_status,
                    url: "",
                    createdBy: expenseClaims.claimant_name,
                    taskType: EXPENSE_CLAIMS,
                    createdDate: expenseClaims.created_date,
                    service: EXPENSES,
                })),
            };
        }
        return { count: 0, data: [] };
    },
};

export default expenseClaimsMapper;
