import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
};

const expensesApiService = apiService(apiConfig.expenses, apiHeaderConfig);

const expensesClient = {
    getExpenseClaimsRecords: (params, requestHeaders) =>
        expensesApiService.get(
            "v1/expense-approval-records",
            params,
            requestHeaders
        ),
};

export default expensesClient;
