import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWRInfinite from "swr/infinite";
import { useCallback, useMemo } from "react";
import { setExpensesClaimsTaskCount } from "../../../redux/actions/taskCountActions";
import { useDispatch } from "react-redux";
import expensesClient from "../clients/expensesClient";
import useUserProfile from "./useUserProfile";
import usePreferences from "./usePreferences";
import { EXPENSES } from "constants/serviceNames";
import expenseClaimsMapper from "../mappers/expensesClaimsMapper";

const useExpenseClaims = () => {
    const dispatch = useDispatch();
    const { userProfileToken, getTokenExpiryTSinMS } = useUserProfile();
    const {
        notificationPreferences,
        servicesInMaintenanceMode,
        preferencesAreLoading,
        servicesBasedOnPermissions,
    } = usePreferences();
    const { throwAsyncError } = useErrorHandling();

    const servicesByPreference = useMemo(
        () =>
            notificationPreferences && !preferencesAreLoading
                ? Object.keys(notificationPreferences)
                      .filter((service) => notificationPreferences[service])
                      .sort((a, b) => a.localeCompare(b))
                : null,
        [notificationPreferences, preferencesAreLoading]
    );

    const getCacheKey = (pageIndex) =>
        !servicesByPreference ||
        !userProfileToken ||
        getTokenExpiryTSinMS(userProfileToken) < Date.now()
            ? null
            : ["expenseClaimRecords", pageIndex, ...servicesByPreference];

    const {
        data: expenseClaimRecordsData,
        size: loadedPageCount,
        setSize: setPagesToLoad,
        isLoading: expenseClaimRecordsAreLoading,
        error: expenseClaimRecordsError,
    } = useSWRInfinite(
        getCacheKey,
        async () => {
            try {
                if (
                    servicesBasedOnPermissions.includes(EXPENSES) &&
                    !servicesInMaintenanceMode?.includes(EXPENSES)
                ) {
                    const expenseClaimRecords =
                        await expensesClient.getExpenseClaimsRecords();

                    const expenseClaimRecordMapped =
                        expenseClaimsMapper.mapAPIExpenseClaimsToClaims(
                            expenseClaimRecords || []
                        );

                    dispatch(
                        setExpensesClaimsTaskCount(
                            expenseClaimRecordMapped?.count || 0
                        )
                    );

                    return expenseClaimRecordMapped;
                }
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.LMSLearningsAPIError,
                    "Unable to fetch claims from Expenses",
                    error
                );
                throw new Error("Unable to fetch claims from Expenses");
            }
        },
        { fallbackData: [], parallel: true, revalidateFirstPage: false }
    );

    const expenseClaimRecords = useMemo(
        () =>
            Array.isArray(
                expenseClaimRecordsData?.[expenseClaimRecordsData.length - 1]
                    ?.data
            )
                ? expenseClaimRecordsData[expenseClaimRecordsData.length - 1]
                      .data
                : [],
        [expenseClaimRecordsData]
    );

    const expenseClaimRecordsCount = useMemo(
        () =>
            expenseClaimRecordsData?.[expenseClaimRecordsData.length - 1]
                ?.count || 0,
        [expenseClaimRecordsData]
    );

    const expenseClaimRecordById = useCallback(
        (id) => expenseClaimRecords.find((record) => record.id === id) || null,
        [expenseClaimRecords]
    );

    return {
        expenseClaimRecordsAreLoading,
        expenseClaimRecordsCount,
        loadedPageCount,
        expenseClaimRecords,
        setPagesToLoad,
        expenseClaimRecordById,
        errorMessage: expenseClaimRecordsError?.message,
    };
};

export default useExpenseClaims;
