// All redux action types separated by slice.

// Alerts slice action types
export const SET_SNACKBAR_QUEUE = "SET_SNACKBAR_QUEUE";
export const APPEND_TO_SNACKBAR_QUEUE = "APPEND_TO_SNACKBAR_QUEUE";

// General slice action types
export const SET_SHOW_USER_INFO = "SET_SHOW_USER_INFO";
export const SET_SHOW_NOTIFICATIONS = "SET_SHOW_NOTIFICATIONS";
export const SET_SHOW_CHAT_FIELD = "SET_SHOW_CHAT_FIELD";
export const SET_IS_EDITING = "SET_IS_EDITING";
export const SET_SELECTED_CUSTOMISATION_TAB = "SET_SELECTED_CUSTOMISATION_TAB";
export const SET_SHOW_FEEDBACK_FORM = "SET_SHOW_FEEDBACK_FORM";
export const SET_SHOW_FEEDBACK_PROMPT = "SET_SHOW_FEEDBACK_PROMPT";
export const SET_CHECK_FEEDBACK_API = "SET_CHECK_FEEDBACK_API";
export const SET_APPROVALS_TASK_COUNT = "SET_APPROVALS_TASK_COUNT";
export const SET_ITSC_APPROVALS_TASK_COUNT = "SET_ITSC_APPROVALS_TASK_COUNT";
export const SET_PROCURE_TO_PAY_APPROVALS_TASK_COUNT =
    "SET_PROCURE_TO_PAY_APPROVALS_TASK_COUNT";
export const SET_PROCURE_TO_PAY_REQUEST_ITEM_TASK_COUNT =
    "SET_PROCURE_TO_PAY_REQUEST_ITEM_TASK_COUNT";
// Auth slice action types
export const SET_AUTH = "SET_AUTH";
export const SET_ACTIVE_OU = "SET_ACTIVE_OU";

// Error handling slice action types
export const SET_ERROR = "SET_ERROR";

// PWA slice action types
export const SET_DEFERRED_INSTALL_PROMPT = "SET_DEFERRED_INSTALL_PROMPT";
export const SET_SHOW_INSTALL_DIALOG = "SET_SHOW_INSTALL_DIALOG";
export const SET_INSTALL_DIALOG_ALREADY_SHOWN =
    "SET_INSTALL_DIALOG_ALREADY_SHOWN";

export const SET_LMS_COURSES_TASK_COUNT = "SET_LMS_COURSES_TASK_COUNT";

export const SET_EXPENSES_CLAIMS_TASK_COUNT = "SET_EXPENSES_CLAIMS_TASK_COUNT";
