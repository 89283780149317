import React from "react";
import PropTypes from "prop-types";
import TaskActionButton from "../../buttons/taskActionButton";
import { CardActions } from "@mui/material";
import BaseTaskCard from "../baseTaskCard";
import { formatDate, getDeviceInfo } from "@sse-digital-services/design-system";
import useExpenseClaims from "../../../services/apiUtils/hooks/useExpenseClaims";
const ExpenseClaimsTaskCard = ({ id, isDashboard }) => {
    const { expenseClaimRecords } = useExpenseClaims();

    if (
        !Array.isArray(expenseClaimRecords) ||
        expenseClaimRecords.length === 0
    ) {
        console.warn("Expense claims list is empty or invalid");
        return null;
    }

    const claimRecord = expenseClaimRecords.find(
        (claimRecord) => claimRecord.id === id
    );

    const { handheld } = getDeviceInfo();
    const expenseUrl = handheld
        ? GLOBAL_CONFIG.expensesMobileUrl
        : GLOBAL_CONFIG.expensesDesktopUrl;

    return (
        <BaseTaskCard
            key={claimRecord.id}
            title="Expense Claim Request"
            fields={[
                {
                    field: "Requested By",
                    value: claimRecord.createdBy,
                },
                {
                    field: "Request Date",
                    value: formatDate(claimRecord.createdDate),
                },
            ]}
            isDashboard={isDashboard}
        >
            <CardActions sx={{ px: 0 }}>
                <TaskActionButton
                    mode="neutral"
                    action="View in Expenses"
                    url={`${expenseUrl}?ClaimId=${claimRecord.id}`}
                />
            </CardActions>
        </BaseTaskCard>
    );
};

ExpenseClaimsTaskCard.propTypes = {
    id: PropTypes.string.isRequired,
    isDashboard: PropTypes.bool,
};

export default ExpenseClaimsTaskCard;
