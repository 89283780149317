import * as React from "react";
import { useCallback, useMemo } from "react";
import useIBuyApprovals from "../../services/apiUtils/hooks/useIBuyApprovals";
import useServiceNowApprovals from "../../services/apiUtils/hooks/useServiceNowApprovals";
import useProcureToPayApprovals from "../../services/apiUtils/hooks/useProcureToPayApprovals";
import useProcureToPayRequestItemTasks from "../../services/apiUtils/hooks/useProcureToPayRequestItemTasks";
import { taskTypeToCard } from "../../constants/taskNames";
import useMyLearningCourses from "services/apiUtils/hooks/useMyLearningCourses";
import useExpenseClaims from "services/apiUtils/hooks/useExpenseClaims";

const useTasks = () => {
    const { serviceNowApprovals, serviceNowApprovalsAreLoading } =
        useServiceNowApprovals();
    const { procureToPayApprovals, procureToPayApprovalsAreLoading } =
        useProcureToPayApprovals();
    const {
        procureToPayRequestItemTasks,
        procureToPayRequestItemTasksAreLoading,
    } = useProcureToPayRequestItemTasks();
    const { approvals, iBuyApprovalsAreLoading } = useIBuyApprovals();
    const { coursesList, coursesAreLoading } = useMyLearningCourses();
    const { expenseClaimRecords, expenseClaimRecordsAreLoading } =
        useExpenseClaims();

    const taskUnion = React.useMemo(() => {
        const tasks = [
            // expensesItems,
            approvals,
            procureToPayApprovals,
            procureToPayRequestItemTasks,
            serviceNowApprovals,
            coursesList,
            expenseClaimRecords,
        ];
        return tasks
            .flat()
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    }, [
        approvals,
        procureToPayApprovals,
        procureToPayRequestItemTasks,
        serviceNowApprovals,
        coursesList,
        expenseClaimRecords,
    ]);

    const tasksAreLoading = useMemo(
        () =>
            serviceNowApprovalsAreLoading ||
            iBuyApprovalsAreLoading ||
            procureToPayApprovalsAreLoading ||
            procureToPayRequestItemTasksAreLoading ||
            coursesAreLoading ||
            expenseClaimRecordsAreLoading,

        [
            serviceNowApprovalsAreLoading,
            iBuyApprovalsAreLoading,
            procureToPayApprovalsAreLoading,
            procureToPayRequestItemTasksAreLoading,
            coursesAreLoading,
            expenseClaimRecordsAreLoading,
        ]
    );

    const taskCards = useCallback(
        (tasks, isDashboard = true) => {
            if (tasks) {
                return tasks.map((task) => {
                    const Card = taskTypeToCard[task.taskType];
                    return (
                        Card && (
                            <Card
                                key={`${task.taskType}-${task.id}`}
                                id={task.id}
                                isDashboard={isDashboard}
                            />
                        )
                    );
                });
            }
            return [];
        },
        [
            approvals,
            procureToPayApprovals,
            procureToPayRequestItemTasks,
            serviceNowApprovals,
        ]
    );

    return { taskUnion, tasksAreLoading, taskCards };
};
export default useTasks;
