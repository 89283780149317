import PropTypes from "prop-types";
import * as React from "react";
import QuickActionTile from "../templates/quickActionTile";
import SingleLinkTile from "../templates/singleLinkTile";

import { getDeviceInfo } from "@sse-digital-services/design-system";
import { quickActionTilesMap } from "../tilesMap";

const ExpensesTile = ({ id }) => {
    const { handheld } = getDeviceInfo();

    const deviceSpecificLink = handheld
        ? GLOBAL_CONFIG.expensesMobileUrl
        : GLOBAL_CONFIG.expensesDesktopUrl;

    return (
        <QuickActionTile id={id}>
            <SingleLinkTile
                link={deviceSpecificLink}
                title={"Expenses"}
                linkText={quickActionTilesMap[id].description}
            />
        </QuickActionTile>
    );
};

ExpensesTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default ExpensesTile;
